.floating-btn {
    margin-top: 20px;
    color: white;
    height: 60px;
    width: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: rgb(79, 203, 93);
    cursor: pointer;
    margin-right: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
}

.whiteClr {
    background: #ffffff;
}