.modal-container {
    padding: 0 35% 0 35%;
    margin-top: 30px;
}

.margin-10 {
    margin: 10px;
}

@media all and (max-width: 800px) {
    .modal-container {
        padding: 0 2% 0 2%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}