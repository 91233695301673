html,
body {
    margin: 0;
    height: 100%;
}

.header {
    width: 100%;
    /* background: #80a4f9; */
}

.header-sec {
    width: 100%;
    background: #5F82F4;
}

.heading-container {
    margin-top: 30vh;
    text-align: center;
}

.heading-container h1 {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: bolder;
}

.heading-container h3 {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: bolder;
}

.heading-container h4 {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
}

.header-image {
    position: absolute;
    top: 0px;
    z-index: -1;
    width: 100%;
}

.book-btn {
    background: white;
    color: rgb(94, 129, 244);
    text-align: center;
    height: 50px;
    width: 328px;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
}

.book-btn2 {
    background: rgb(94, 129, 244);
    color: white;
    text-align: center;
    height: 62px;
    width: 436px;
    display: inline-block;
    padding: 15px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.resend-btn {
    margin-top: 10px;
    margin-left: 50px;
    color: white;
    height: 21px;
    width: 250px;
    display: flex;
    border-radius: 3px;
    align-items: left;
    justify-content: center;
    background-color: rgb(94, 129, 244);
    cursor: pointer;
}

.resend-btn-disabled {
    pointer-events: none;
    opacity: 0.4;
    margin-top: 10px;
    margin-left: 50px;
    color: white;
    height: 21px;
    width: 250px;
    display: flex;
    border-radius: 3px;
    align-items: left;
    justify-content: center;
    background-color: rgb(94, 129, 244);
}

.services-container {
    margin-top: 70vh;
    text-align: center;
    width: 99%;
    margin-bottom: 50px;
}

.services-images {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top: 50px;
}

.services-images div {
    position: relative;
}

.services-images>div {
    cursor: pointer;
}

.services-images>div>span {
    position: absolute;
    bottom: 7%;
    right: 34%;
    font-size: 23px;
    color: rgb(201, 215, 252);
}

.services-container h3 {
    font-size: 36px;
    color: rgb(30, 30, 93);
}

.book-services {
    text-align: center;
    background-image: url("../images/book-services-bg.png");
    padding-bottom: 4%;
    padding-top: 10%;
}

.book-services h3 {
    font-size: 36px;
    color: rgb(30, 30, 93);
}

.steps-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 50px;
}

.steps-container h3 {
    margin-left: 6%;
    margin-top: 0;
    font-size: 24px;
    color: rgb(30, 30, 93);
}

.steps-container div {
    text-align: left;
}

.steps-container>div>div {
    margin-left: 6%;
    width: 280px;
    color: rgb(143, 152, 179);
}

.features-container {
    text-align: center;
    margin: 60px 0 112px;
}

.features-container h3 {
    font-size: 36px;
    color: rgb(30, 30, 93);
}

.features-tab {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.feature-subtab {
    display: flex;
    width: 500px;
    align-items: flex-start;
    margin-top: 30px;
}

.feature-subtab div {
    width: 300px;
    text-align: left;
    margin-left: 40px;
}

.feature-subtab h4 {
    margin-top: 0;
    font-size: 24px;
    color: rgb(30, 30, 93);
}

.feature-subtab p {
    margin-top: 15px;
    color: rgb(143, 152, 179);
}

.locations-tab {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.locations-subtab {
    display: flex;
    width: 500px;
    align-items: flex-start;
    margin-top: 30px;
}

.locations-subtab div {
    width: 300px;
    text-align: left;
    margin-left: 15px;
}

.locations-subtab h4 {
    margin-top: 15px;
    margin-left: 0px;
    font-size: 26px;
    color: rgb(30, 30, 93);
}

.locations-subtab p {
    margin-top: 15px;
    color: rgb(143, 152, 179);
}


.download-app {
    background-image: url("../images/download_app_bg.svg");
    height: 537px;
    position: relative;
    margin-bottom: 130px;
    background-size: cover;
}

.download-text {
    width: 28%;
    text-align: left;
    font-size: 12px;
    margin-left: 10%;
    padding-top: 8%;
    color: white;
}

.download-text h3 {
    font-size: 36px;
}

.download-text p {
    margin: 25px 0px;
}

.slider-container {
    width: 43%;
    display: inline-block;
    margin-top: 2%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 0px !important;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: rgb(94, 129, 244);
    font-size: 8px;
}

.slick-dots li button:before {
    font-size: 8px;
}

.slick-dots-new li.slick-active button:before {
    opacity: 1;
    color: rgb(94, 129, 244);
}

.footer {
    background-color: rgb(40, 40, 69);
}

.footer-container {
    display: flex;
    justify-content: space-around;
    padding: 35px;
    flex-wrap: wrap;
}

.slick-slide img {
    display: inline-block;
}

.footer-container>div>span {
    font-size: 12px;
    color: #7a798c;
    letter-spacing: 1.5;
}

.footer-container>div>ul {
    list-style-type: none;
    padding: 0px;
    padding-top: 10px;
}

.footer-container li {
    padding: 5px 0px;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.footer-container li a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
    color: #fff;
}

.avail-app-container {
    display: flex;
    margin-bottom: 5px;
    padding-top: 10px;
}

.avail-app {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    color: #9493a2;
}

.rights {
    text-align: center;
    color: white;
    font-size: 16px;
    padding-bottom: 20px;
}

.rights span {
    color: rgb(94, 129, 244);
}

.service-selected {
    display: flex;
    margin: 3% 14% 3% 6%;
}

.service-selected>div {
    display: flex;
    justify-content: center;
}

.service-selected img {
    height: 130px;
}

.service-para {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    color: rgb(143, 152, 179);
}

.service-para h5 {
    padding: 3% 6%;
}

.service-subdiv {
    padding: 0 9% 0 6%;
    display: flex;
    justify-content: space-between;
}

.service-subdiv img {
    height: 50px;
}

.price-tag {
    color: rgb(94, 129, 244);
    font-size: 24px;
}

.service-subdiv>div {
    display: flex;
    flex-direction: column;
}

.service-subdiv .heading {
    color: rgb(30, 30, 93);
    font-size: 16px;
    padding: unset;
}

.service-subdiv .sub-heading {
    font-size: 14px;
}

.only-heading {
    margin: 14px 0;
}

hr {
    margin: 10px 0;
}

.head-container {
    background: #7da2f9;
}

.heading {
    text-align: center;
    padding: 30px 0 100px;
    font-size: 48px;
    color: white;
    font-weight: 600;
}

.content-container {
    padding: 28px 10%;
    color: rgb(143, 152, 179);
    font-size: 16px;
    line-height: 20px;
}

.content-container-services {
    color: rgb(143, 152, 179);
    font-size: 16px;
    line-height: 20px;
}


/* .content-container {
  padding: 48px 21%;
  color: rgb(143, 152, 179);
  font-size: 16px;
  line-height: 20px;
} */

.content-container ul {
    margin-top: 35px;
    list-style: none;
    padding-left: 15px;
}

.content-container ol {
    padding-left: 18px;
}

.content-container ul li {
    margin-top: 25px;
}

.content-container h6 {
    color: rgb(30, 30, 93);
    font-size: 14px;
    margin-bottom: 16px;
}

.content-container ul li::before {
    content: "\2022";
    color: rgb(30, 30, 93);
    font-weight: bold;
    display: inline-block;
    width: 1em;
}

.content-container>div {
    margin-top: 30px;
}

a {
    color: white;
    text-decoration: none;
}


/* .MuiPaper-root {
    background-color: transparent !important;
} */

.MuiStepper-horizontal {
    flex-direction: row;
    background-color: transparent !important;
}

.MuiStepIcon-root {
    height: 26px !important;
    width: 26px !important;
}

.MuiStepIcon-text {
    font-size: 14px !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: rgb(94, 129, 244) !important;
}

.MuiStepLabel-label {
    font-size: 14px !important;
    color: rgb(94, 129, 244) !important;
}

.booking-container {
    background-color: rgb(249, 249, 251);
    margin-top: 6%;
    text-align: center;
}

.booking-container h3 {
    color: rgb(30, 30, 93);
    font-size: 36px;
    font-weight: 700;
    padding-top: 25px;
}

.date-time-container {
    width: 47%;
    text-align: left;
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: rgb(94, 129, 244) !important;
    width: 20%;
    align-self: flex-end;
    height: 51px;
    font-size: 14px !important;
}

.date-time-container>div {
    margin-bottom: 60px;
}

.date-time-subcontainer {
    display: flex;
}

.date-day {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0px 4px 30px rgba(74, 91, 185, 0.13);
    margin: 12px 12px 0 0;
    border-radius: 10px;
    min-width: 80px;
}

.date-day .date {
    color: rgb(168, 176, 201);
    font-size: 12px;
}

.date-day .day {
    color: rgb(30, 30, 93);
    font-size: 12px;
}

.active {
    background: rgba(94, 129, 244, 0.5);
    padding: 10px;
    border-radius: 10px;
}

.active .date {
    color: white;
    font-size: 12px;
}

.active .day {
    color: white;
    font-size: 12px;
}

.date-time-container h4 {
    font-size: 14px;
    color: rgb(30, 30, 93);
}

.booking-details {
    width: 20%;
    background: white;
    margin-left: 1%;
    text-align: left;
    height: max-content;
    padding: 14px 33px;
}

.booking-details-subcontainer {
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
}

.right-span {
    width: max-content;
    font-size: 14px;
    color: rgb(30, 30, 93);
    font-weight: 600;
    text-align: right;
}

.left-span {
    width: 56px;
    font-size: 14px;
    color: rgb(143, 152, 179);
    font-weight: 600;
}

.map-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
}

.phone-number-container {
    border: 1px solid rgb(236, 237, 242);
    height: 51px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.btn-login {
    margin-top: 20px;
    color: white;
    height: 51px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: rgb(94, 129, 244);
    cursor: pointer;
}

.container-icon-image {
    margin-top: 20px;
    color: white;
    height: 55px;
    width: 55px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: rgb(79, 203, 93);
    cursor: pointer;
}

input.phone-number {
    border: none;
}

input.phone-number:focus {
    outline-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiExpansionPanel-rounded {
    border-radius: 8px;
}

.MuiPaper-elevation1 {
    box-shadow: 0px -1px 0px -1px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 1px rgba(0, 0, 0, 0.12) !important;
}

.profile-container {
    text-align: center;
}

.white-box {
    background: white;
    width: 380px;
    box-shadow: 0px 4px 30px 0px rgba(74, 91, 185, 0.08);
    margin-bottom: 170px;
    margin-top: 110px;
}

.address-container {
    margin-left: 24px;
    text-align: left;
    padding: 34px;
}

.personal-detail-container {
    text-align: left;
    padding: 34px;
}

.address-container h3 {
    color: rgb(30, 30, 93);
    margin: 0px;
}

.personal-detail-container h3 {
    color: rgb(30, 30, 93);
    margin: 0px;
}

.white-box2 {
    width: 314px;
    border: 1px solid rgb(236, 237, 242);
    border-radius: 8px;
    margin-top: 14px;
    padding: 18px;
}

.address-container h5 {
    color: rgb(30, 30, 93);
    margin: 0px;
}

.address-container p {
    color: rgb(168, 176, 201);
    font-size: 12px;
    margin: 0;
    margin-top: 8px;
}

.promo-code-container {
    width: 784px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 30px 0px rgba(74, 91, 185, 0.08);
    margin-top: 90px;
    text-align: left;
    padding: 34px;
    margin-bottom: 90px;
}

.promo-code-container h3 {
    font-weight: bolder;
    margin: 0px;
    color: rgb(30, 30, 93);
}

.promo-code-box {
    height: 36px;
    width: 364px;
    background: rgb(245, 245, 245);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(30, 30, 93);
    font-weight: bold;
}

.tab {
    font-size: 14px;
    font-weight: bold;
    color: rgb(218, 218, 218);
    cursor: pointer;
    padding: 3px 0;
}

.tabactive {
    color: rgb(30, 30, 93);
    border-bottom: 3px solid rgb(94, 129, 244);
}

.card-number-frame {
    flex: 220px 0 0;
    background: #efeff5;
    height: 50px;
    margin-right: 20px;
    padding: 8px;
    text-align: center;
}

.expiry-date-frame {
    flex: 100px 0 0;
    background: #efeff5;
    height: 50px;
    margin-right: 20px;
    padding: 8px;
    text-align: center;
}

.cvv-frame {
    flex: 60px 0 0;
    background: #efeff5;
    height: 50px;
    padding: 8px;
    text-align: center;
}

#tick-mark {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 26px;
    margin-top: -4px;
}

#tick-mark::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: rgb(94, 129, 244);
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

#tick-mark::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: rgb(94, 129, 244);
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

@media all and (max-width: 800px) {
    .heading-container {
        margin-top: 20vh;
        padding: 0 10px;
    }
    .heading-container h3 {
        font-size: 28px;
    }
    .services-container {
        margin-top: 40vh;
    }
    .services-images {
        flex-wrap: wrap;
    }
    .steps-container {
        flex-wrap: wrap;
    }
    .features-container {
        padding: 0 25px 0 25px;
    }
    .feature-subtab div {
        width: 228px;
        margin-left: 15px;
    }
    .book-services>h3 {
        margin: 0 60px;
    }
    .feature-subtab h4 {
        font-size: 18px;
    }
    .service-subdiv {
        padding: 0;
    }
    .service-para h5 {
        padding: 8% 0%;
    }
    .book-services {
        background-image: url("../images/book-service-bg-mobile.png");
        padding-bottom: 6%;
        background-size: cover;
        padding-right: 20px;
        padding-left: 20px;
    }
    .book-services h3 {
        font-size: 24px;
        color: rgb(30, 30, 93);
    }
    .steps-container h3 {
        margin-left: 6%;
        margin-top: -20px;
        font-size: 18px;
        color: rgb(30, 30, 93);
    }
    .steps-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 4px;
        margin-bottom: 10px;
    }
    .footer-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        flex-wrap: wrap;
    }
    .footer-container li {
        padding: 5px 0px;
        color: white;
        font-size: 12px;
    }
    .slider-container {
        width: 96%;
        display: inline-block;
        margin-top: 2%;
    }
    .download-text {
        width: 80%;
        text-align: center;
        font-size: 12px;
        margin-left: 10%;
        padding-top: 8%;
        color: white;
        padding-bottom: 6%;
    }
    .features-container h3 {
        font-size: 24px;
        color: rgb(30, 30, 93);
    }
    .download-text h3 {
        font-size: 24px;
    }
    .services-container h3 {
        font-size: 24px;
        color: rgb(30, 30, 93);
    }
    .features-container {
        text-align: center;
        margin: 60px 0;
    }
    .mobile-apps {
        display: flex;
    }
    .avail-app-container {
        margin-right: 15px;
    }
    .services-images>div>img {
        margin-bottom: 25px;
    }
    .service-selected {
        display: flex;
        flex-direction: column;
        margin: 3% 6% 10% 6%;
    }
    .download-app {
        background-image: url("../images/mobile_download_bg.png");
        height: 421px;
        position: relative;
        margin-bottom: 200px;
        background-size: cover;
    }
    .services-images>div>span {
        position: absolute;
        bottom: 13%;
        right: 34%;
        font-size: 23px;
        color: rgb(201, 215, 252);
    }
    .book-btn {
        width: 100%;
    }
    .header-image>img {
        width: 100vw;
    }
    .book-btn2 {
        width: 100%;
    }
    .content-container-faq {
        padding-left: 14px;
        padding-right: 19px;
        font-size: 12px;
        background-color: rgba(255 255 255 0.3);
    }
    .content-container-privacy {
        padding-left: 32px;
        padding-right: 32px;
        font-size: 12px;
    }

    .content-container-service {
        padding-left: 5px;
        padding-right: 32px;
        font-size: 12px;
    }


    .content-container-tc {
        padding-left: 29px;
        padding-right: 29px;
        font-size: 12px;
    }
    .content-container ul {
        padding-left: 0;
    }
    .content-container ul li {
        margin-top: 20px;
    }
    .content-container ul li::before {
        width: 1.5em;
    }
    .content-container ol {
        padding-left: 12px;
    }
    .content-container>div>p {
        font-size: 14px;
    }
    .content-container h6 {
        margin-bottom: 10px;
    }
    .booking-container {
        margin-top: 75px;
    }
    .booking-container h3 {
        font-size: 18px;
    }
    .MuiStepper-root {
        padding-top: 12px !important;
    }
    .map-container {
        padding: 1px;
        width: 100%;
        padding-bottom: 60px;
    }
    .map-container>div>p {
        margin: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        color: rgb(30 30 93);
        text-align: left;
        margin-left: 22px;
        letter-spacing: 1px;
    }
    .date-time-container {
        width: 100%;
        padding: 0px 22px;
    }
    .date-time-container>div {
        padding-bottom: 39px;
        margin-bottom: 0;
    }
    .service-content-price {
        margin-bottom: 2.5rem;
        margin-top: 1rem;
        font-size: 18px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgb(0, 0, 0.8);
    }
    .service-content-price .h3 {
        font-size: 1.875rem;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: black;
    }
    .service-selected-price {
        display: flex;
        margin: 3% 6% 3% 0%;
    }
    .promo-code-container {
        width: 90%;
        margin: 20px;
        padding: 24px;
    }
    .promo-code-box {
        width: 200px;
    }
    .book-btn3 {
        width: 80%;
    }
    .order-cont {
        border: 1px solid rgb(236, 237, 242);
        padding: 12px;
        margin-bottom: 10px;
        border-radius: 8px;
    }
    .sub-container {
        flex-direction: column;
    }
    .white-box {
        margin: 22px;
        width: auto;
        border-radius: 10px;
        padding: 20px;
    }
    .white-box2 {
        width: auto;
    }
}